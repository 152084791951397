import * as React from "react";
import tw from "twin.macro";
import { styled } from "baseui";
import { acss } from "@/shared/utils";

type Props = {
  children: React.ReactNode;
  src: string;
  mobileSrc?: string;
  alt: string;
  overlapSection?: boolean;
  isCentered?: boolean;
  showDisclaimer?: boolean;
};

const HeroContainer = ({ children, overlapSection, isCentered }: Props) => {
  return (
    <RootContainer $overlap={overlapSection}>
      <div
        className="min-h-screen"
        style={{
          backgroundColor: "#ffd1d7",
        }}
      />
      {children && (
        <Body>
          <ContentContainer $overlap={overlapSection} $centered={isCentered}>
            <TextWrap $centered={isCentered}>{children}</TextWrap>
          </ContentContainer>
        </Body>
      )}
    </RootContainer>
  );
};
const TextWrap = styled(
  "div",
  ({ $centered }: { $centered: Props["isCentered"] }) => {
    let styles = acss(tw`max-w-xl mx-auto md:mx-0`);
    if ($centered) {
      styles = acss(styles, [tw`md:(mx-auto max-w-lg) lg:(mx-auto max-w-xl)`]);
    }
    return styles;
  }
);
const ContentContainer = styled(
  "div",
  ({
    $overlap,
    $centered,
  }: {
    $centered: Props["isCentered"];
    $overlap: Props["overlapSection"];
  }) => {
    let styles = acss(tw`container px-4 text-center md:text-left`);
    if ($centered) {
      styles = acss(styles, [tw`md:text-center`]);
    }
    if ($overlap) {
      styles = acss(styles, [tw`xl:mt-[-50px]`]);
    }
    return styles;
  }
);
const Body = styled(
  "div",
  acss(tw`w-full h-auto absolute top-[60%] lg:top-[50%]`, [
    {
      left: "50%",
      transform: "translate(-50%, -70%)",
      zIndex: 2,
    },
  ])
);
const RootContainer = styled(
  "div",
  ({ $overlap }: { $overlap: Props["overlapSection"] }) => {
    let styles = acss(
      tw`w-full h-full  relative bg-white min-h-[400px] lg:min-h-[550px]`
    );
    if ($overlap) {
      styles = acss(styles, [tw`xl:min-h-[700px]`]);
    }
    return styles;
  }
);
const Title = styled("h1", acss(tw`text-2xl lg:text-4xl  font-bold`));
const SubTitle = styled(
  "p",
  acss(tw` font-secondary uppercase tracking-wider text-lg my-2 `)
);
const Description = styled(
  "div",
  acss(tw`text-sm mt-12 sm:mt-8 sm:text-base lg:text-lg  lg:(mt-20 pr-36)`)
);
const ActionButtons = styled("div", acss(tw`mt-6 sm:mt-2 lg:mt-4 mb-4`));

export const Hero = Object.assign(HeroContainer, {
  Title,
  SubTitle,
  Description,
  ActionButtons,
});
