import type { StyleObject } from "styletron-standard";
import { expand } from "inline-style-expand-shorthand";
import merge from "lodash.merge";

/**
 * Atomic CSS Util
 * Helps overcome the `styletron-engine-atomic` tradeoffs:
 * # Overlapping media queries on same element -> fixed by lodash.merge
 * # Mixing shorthand and longform properties on same element -> fixed by inline-style-expand-shorthand
 * @see https://github.com/styletron/styletron/blob/master/packages/styletron-engine-atomic/TRADEOFFS.md
 */
type AtomicCssFunction = (
  styles: StyleObject,
  stylesToMerge?: StyleObject[]
) => StyleObject;
export const acss: AtomicCssFunction = (target, sources) => {
  const styles = expand(target);
  if (sources?.length) {
    const expandedStyles = sources.map((s) => expand(s));
    return merge(styles, ...expandedStyles);
  }
  return styles;
};
