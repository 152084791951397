import React from "react";
import LogoSvg from "@/assets/logo.svg";
import NextLink from "next/link";
import tw from "twin.macro";
import { styled, useStyletron } from "baseui";
import { acss } from "@/shared/utils";
import hs from "src/app/layout/layout.module.css";

// todo: nav links come from cms + use same links for mobile/desktop nav
export const Header = (): JSX.Element => {
  const [css] = useStyletron();
  return (
    <RootContainer className={hs.header}>
      <NavContainer>
        <NavList className={css(acss(tw`justify-start flex`))}></NavList>
        <NextLink href="/" passHref>
          <NavList $as="a">
            <LogoSvg
              className={css({
                width: "100%",
                maxWidth: "200px",
                minWidth: "157px",
                margin: "0 auto",
                paddingTop: "1rem",
              })}
            />
          </NavList>
        </NextLink>
        <NavList className={css(acss(tw`flex justify-end`))}>
          <span className={css(acss(tw` mt-3 block`))}></span>
        </NavList>
      </NavContainer>
    </RootContainer>
  );
};

const NavList = styled("nav", acss(tw`flex-1`));
const RootContainer = styled("header", acss(tw`sticky z-50 top-0`));
const NavContainer = styled("div", acss(tw`container flex items-center`));
