import * as React from "react";
import type { NextPage } from "next";
import { Hero } from "@/shared/components";
import { useRouter } from "next/router";
import { BLOOMSYBOX_SITE_URL } from "@/shared/constants";
import { AppLayout } from "@/app/layout";
import { Button } from "@/shared/components/ui";

const Home: NextPage = () => {
  const router = useRouter();
  return (
    <>
      <section>
        <Hero
          isCentered
          src="/assets/home-hero.jpg"
          mobileSrc="/assets/home-hero-mobile.jpg"
          alt=""
        >
          <div className="pt-14">
            <Hero.Title>
              Our story comes to an <br />
              end, but the journey continues!
            </Hero.Title>
            <p className="mt-12 mb-8 font-normal">
              Starting now, SimpleBlooms will no longer offer flower
              subscriptions. But fret not! We would love to invite you to a new
              floral adventure! Join us at BloomsyBox.com and continue receiving
              our stunning flowers regularly, delivered to your doorstep. <br />
              Don&apos;t miss the joy our flowers bring to your life.
            </p>
            <Hero.ActionButtons>
              <Button
                kind="secondary"
                onClick={() => router.push(BLOOMSYBOX_SITE_URL)}
              >
                Go to BloomsyBox.com
              </Button>
              <p className="mt-8">
                Thank you for trusting us and being part of the flower
                lovers&apos; family.
              </p>
              <p>
                <b>The SimpleBlooms Team</b>
              </p>
            </Hero.ActionButtons>
          </div>
        </Hero>
      </section>
    </>
  );
};

export default Object.assign(Home, { Layout: AppLayout });
