import * as React from "react";
import { styled } from "baseui";
import { Header } from "./header";

type Props = {
  children: React.ReactNode;
};
export const Layout = ({ children }: Props): JSX.Element => {
  return (
    <div>
      <Header />
      <MainContainer>{children}</MainContainer>
    </div>
  );
};
const MainContainer = styled("main", {});
