/* eslint-disable @typescript-eslint/no-unused-vars */
// see: https://github.com/uber/baseweb/blob/master/src/button/styled-components.js
import type { ButtonProps } from "baseui/button";
import {
  KIND,
  SHAPE,
  SIZE,
  StyledBaseButton as BaseStyledBaseButton,
} from "baseui/button";
import { acss } from "@/shared/utils";
import tw from "twin.macro";
import { withStyle } from "baseui";

type SharedStylePropsT = {
  $kind: ButtonProps["kind"];
  $isSelected: boolean;
  $shape: ButtonProps["shape"];
  $size: ButtonProps["size"];
  $isLoading: boolean;
  $disabled: boolean;
  $isFocusVisible: boolean;
};

function getHoverStyles({ $kind }: Partial<SharedStylePropsT>) {
  if ($kind === KIND.secondary) { 
     return acss(tw`hover:(bg-gray-800 text-white)`); 
  }
  return acss(tw``); 
}
function getFontStyles({ $size }: Partial<SharedStylePropsT>) {
  switch ($size) {
    case SIZE.mini:
      return acss(tw`text-xs`);
    case SIZE.compact:
      return acss(tw`text-sm`);
    case SIZE.large:
      return acss(tw`text-lg`);
    default:
      return acss(tw`text-base`);
  }
}

function getPaddingStyles({ $size, $shape }: Partial<SharedStylePropsT>) {
  const iconShape =
    $shape === SHAPE.square ||
    $shape === SHAPE.circle ||
    $shape === SHAPE.round;
  switch ($size) {
    case SIZE.mini:
      return acss(tw`py-2 hover:bg-white`, [iconShape ? tw`px-2` : tw`px-3`]);
    case SIZE.compact:
      return acss(tw`py-4`, [iconShape ? tw`px-4` : tw`px-4`]);
    case SIZE.large:
      return acss(tw`py-5`, [iconShape ? tw`px-5` : tw`px-16`]);
    default:
      return acss(tw`py-4`, [iconShape ? tw`px-4` : tw`px-10`]);
  }
}

function getBorderRadiiStyles({ $size, $shape }: Partial<SharedStylePropsT>) {
  if ($shape === SHAPE.pill) {
    if ($size === SIZE.compact) {
      return acss(tw`rounded-[30px]`);
    } else if ($size === SIZE.large) {
      return acss(tw`rounded-[42px]`);
    } else {
      return acss(tw`rounded-[38px]`);
    }
  } else if ($shape === SHAPE.circle || $shape === SHAPE.round) {
    return acss(tw`rounded-full`);
  }

  return acss(tw`rounded`);
}

const getButtonStyleOverrides = ({
  $size,
  $kind,
  $shape,
  $isLoading,
  $isSelected,
  $disabled,
  $isFocusVisible,
}: SharedStylePropsT) => {
  return acss(tw`uppercase tracking-wider font-bold`, [
    getFontStyles({ $size }),
    getBorderRadiiStyles({ $size, $shape }),
    getPaddingStyles({ $size, $shape }),
    getHoverStyles({ $kind }),
  ]);
};
export const  StyledBaseButton = withStyle(
  BaseStyledBaseButton,
  getButtonStyleOverrides
);
