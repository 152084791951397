import * as React from "react";
import { Button as BaseButton } from "baseui/button";
import { StyledBaseButton } from "./styled-button";

export const Button: typeof BaseButton = React.forwardRef((props, ref) => {
  return (
    <BaseButton
      overrides={{
        BaseButton: StyledBaseButton,
      }}
      {...props}
      ref={ref}
    />
  );
});
Button.displayName = BaseButton.displayName;
